import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "./axios";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Admin from "./Login/Admin";
import Leaderboard from "./Leaderboard/Leaderscoreboard";
import Registration from "./Register/Registration";
import Viewcode from "./View/Viewcode";
import Gamescore from "./Score/Gamescore";
import ExportData from "./Export/ExportData";
import UnprotectedRoute from "./UnProtectedRoute";
import PrivateRoute from "./PrivateRoute";

function App() {
  const { refresh, admin } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [checkingAuth, setCheckingAuth] = useState(true);

  useEffect(() => {
    setCheckingAuth(true);
    const API = process.env.REACT_APP_API_URL;
    axios
      .get(`${API}/api/admin/auth`, { withCredentials: true })
      .then((response) => {
        console.log("ADMIN: ", response.data);
        dispatch({
          type: "admin",
          payload: {
            login: response.data.login,
            details: response.data.details,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setCheckingAuth(false));
  }, [refresh, dispatch]);

  console.log(admin, "admin");

  if (checkingAuth) {
    return <div className="bgloading"><p>Loading...</p></div>;
  }
  return (
    <Router>
      <div className="mainhomecontainer">
        <Routes>
          <Route element={<UnprotectedRoute />}>
            <Route path="/login" element={<Admin />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Leaderboard />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/view" element={<Viewcode />} />
            <Route path="/score" element={<Gamescore />} />
            <Route path="/export" element={<ExportData />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
