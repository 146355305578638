import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Styles from "../Styles/code.module.scss";
import Codelogo from "../Assets/logo/logo@2x.webp";
import Homebtn from "../Assets/logo/home@2x.webp";

const Viewcode = () => {
  const { state } = useLocation();
  const { user } = state || {};


  const navigate = useNavigate();
  return (
    <div className={Styles.codemaincontainer}>
      <div className={Styles.codeform}>
        <div className={Styles.codelogo}>
          <img src={Codelogo} alt="codelogo" />
        </div>
        <div className={Styles.codecontent}>
          <h6>YOUR UNIQUE CODE</h6>
          <h1>{user.data.uniqueCode}</h1>
          <p>
            Please keep your
            <br />
            unique code safe to play games
          </p>
        </div>
        <div className={Styles.codebtn} onClick={() => navigate("/register")}>
          <img src={Homebtn} />
        </div>
      </div>
    </div>
  );
};

export default Viewcode;
