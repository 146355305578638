import React, { useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import countryList from "react-select-country-list";
import Flag from "react-world-flags";
import Styles from "../Styles/register.module.scss";
import Reglogo from "../Assets/logo/logo@2x.webp";

const Registration = () => {
  const [name, setName] = useState("");
  const [country, setCountry] = useState(null);
  const [buttonState, setButtonState] = useState(false);
  const form = useRef();
  const navigate = useNavigate();

  const options = useMemo(() => {
    return countryList()
      .getData()
      .map((country) => ({
        label: country.label,
        value: country.value,
        code: country.value,
      }));
  }, []);

  const changeHandler = (value) => {
    setCountry(value);
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#ffdf00",
      border: "none",
      outline: "none",
      padding: "0.5em 8em",
      transform: "skewX(-20deg)",
      marginTop: "0.5em",
      marginBottom: "0.5em",
      textAlign: "center",
      fontFamily: '"29lt_bukraregular"',
      color: "#152b6f",
      boxShadow: "none",
      borderRadius: 0, // Remove border radius
      "&:hover": {
        border: "none",
      },
      "& input": {
        transform: "none", // Remove skew from the input field
        textAlign: "center", // Center-align text
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: "#152b6f",
      textAlign: "center",
      fontWeight: 500,
      transform: "skewX(20deg)",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#152b6f",
      textAlign: "center",
      transform: "skewX(20deg)",
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "#6673AC", // Set menu background color
      marginTop: "0.5em",
      marginBottom: "0.5em",
      borderRadius: 0, // Remove border radius
    }),
    option: (base, state) => ({
      ...base,
      color: state.isSelected ? "#fff" : "#fff", // Set text color
      backgroundColor: state.isSelected ? "#7081C1" : (state.isFocused ? "#7081C1" : "#6673AC"), // Set hover and selected item background color
      textAlign: "center",
      cursor: "pointer", // Add pointer cursor
      "&:hover": {
        backgroundColor: "#7081C1", // Set hover background color
        color: "#fff", // Set hover text color
      },
    }),
  };
  
  

  const formatOptionLabel = ({ label, code }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Flag code={code} height="16" style={{ marginRight: "10px" }} />
      <span>{label}</span>
    </div>
  );

  const isEnglish = (text) =>
    /^[A-Za-z0-9@.,;!#$%&'()*+\/=?^_`{|}~\s-]*$/.test(text);

  const validateEnglishInput = (e, setState) => {
    const value = e.target.value;
    if (!isEnglish(value)) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;"> Please Type Only in English</div>',
        timer: 2000,
      });
    } else {
      setState(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name.trim()) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: `<div>Please Fill All the Fields</div>`,
        timer: 4000,
      });
      return;
    }

    if (!isEnglish(name)) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;"> Please Fill in English Only</div>',
        timer: 4000,
      });
    } else if (name.trim() === "") {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;"> Name Cannot Be Empty</div>',
        timer: 4000,
      });
    } else if (!country) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;"> Please Select a Country</div>',
        timer: 4000,
      });
    } else {
      setButtonState(true);
      Swal.fire({
        title: "",
        showCloseButton: false,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: `
              <div style="text-align: center;">
                <div class="loader"></div>
                <div style="color: white;">Submitting Your Data...</div>
              </div>
            `,
        timer: 4000,
      });

      const API = process.env.REACT_APP_API_URL;
      axios
        .post(
          `${API}/api/users/signup`,
          { name, country: country.value },
      
        )
        .then((response) => {
          setButtonState(false);
          if (!response.data.err) {
            Swal.fire({
              title: "Registration Successful!",
              icon: "success",
              showCloseButton: true,
              showConfirmButton: false,
              customClass: {
                popup: "custom-swal",
                htmlContainer: "custom-html",
                title: "swal2-title",
              },
              backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
              html: '<div style="color: white;">Please Check Your Unique code</div>',
              showConfirmButton: false,
              timer: 1500,
            });

            navigate("/view", { state: { user: response.data } });
          } else {
            setButtonState(false);
            Swal.fire({
              title: "Oops...",
              icon: "error",
              showCloseButton: true,
              showConfirmButton: false,
              customClass: {
                popup: "custom-swal",
                htmlContainer: "custom-html",
                title: "swal2-title",
              },
              backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
              html: '<div style="color: white;"> Registration Failed</div>',
              showConfirmButton: false,
              timer: 1500,
            });
            console.log("Some error occurred");
          }
        })
        .catch((err) => {
          setButtonState(false);
          let errorMessage = "Something Went Wrong!";
          if (err.response) {
            if (err.response.data && err.response.data.message) {
              errorMessage = err.response.data.message;
            } else {
              errorMessage = `Error: ${err.response.status}`;
            }
          } else if (err.request) {
            errorMessage = "No response received from the server.";
            console.log(err.request);
          } else {
            errorMessage = err.message;
          }
          Swal.fire({
            title: "Oops...",
            icon: "error",
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
              popup: "custom-swal",
              htmlContainer: "custom-html",
              title: "swal2-title",
            },
            backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
            html: `<div style="color: white;">${errorMessage}</div>`,
            showConfirmButton: false,
            timer: 4000,
          });
        });
    }
  };

  return (
    <div className={Styles.regmaincontainer}>
      <div className={Styles.regform}>
        <div className={Styles.reglogo}>
          <img src={Reglogo} alt="reglogo" />
        </div>
        <h4>REGISTRATION</h4>
        <form ref={form} onSubmit={handleSubmit} className={Styles.regformmain}>
          <div>
            <input
              type="text"
              value={name}
              placeholder="Name"
              onChange={(e) => validateEnglishInput(e, setName)}
            />
          </div>
        
          <div>
            <Select
              options={options}
              value={country}
              onChange={changeHandler}
              placeholder="Country"
              styles={customStyles}
              formatOptionLabel={formatOptionLabel}
            />
          </div>

          <div>
            <button type="submit" disabled={buttonState}>
              Register
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Registration;
