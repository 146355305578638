

import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { format } from 'date-fns';
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Styles from "../Styles/register.module.scss";
import Reglogo from "../Assets/logo/logo@2x.webp";

const MySwal = withReactContent(Swal);

const ExportData = () => {
  const [data, setData] = useState([]);
  const [resetData, setResetData] = useState([]);

  const handleExportClick = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/game_results/all_result`);
      const usersWithScores = response.data.completedUsers;
      const usersWithNotCompletedAll = response.data.notCompletedUsers;

      const combinedUsers = [...usersWithScores, ...usersWithNotCompletedAll];

      if (!combinedUsers || combinedUsers.length === 0) {
        toast.info("No data available in user database", {});
        return;
      }

      const formattedData = combinedUsers.map((item) => {
        const programs = item.user.programs || [];
        const programData = programs.reduce((acc, program) => {
          acc[`programNum${program.programNum}`] = program.score || 0;
          return acc;
        }, {});

        const formatDateTime = (dateString) => {
          return format(new Date(dateString), 'dd/MM/yyyy HH:mm:ss');
        };

        return {
          Date: formatDateTime(item.user.createdAt),
          Id: item.user._id,
          UniqueCode: item.user.uniqueCode,
          Name: item.user.name,
          "C2 Bike Sprint": programData.programNum1 || 0,
          "Football Keep Ups": programData.programNum2 || 0,
          "Basketball Challenge": programData.programNum3 || 0,
          Score: item.sumOfScores || 0,
          CompletedAllPrograms: item.completedAllPrograms ? "Yes" : "No",
        };
      });

      const csvRows = [];
      const headers = Object.keys(formattedData[0]);
      csvRows.push(headers.join(","));
      formattedData.forEach((row) => {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      });
      const csvString = csvRows.join("\n");

      const blob = new Blob([csvString], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", "exported_data.csv");
      a.click();
      window.URL.revokeObjectURL(url);
      toast.success("Data exported successfully!", {});
    } catch (error) {
      console.error("Error exporting collection:", error);
      toast.error("Failed to export data", {});
    }
  };

  const handleResetClick = async () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Please export all data before resetting. This action will reset all data. This cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reset it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/data/reset_Collection`);
          console.log("Reset Response:", response);  // Log the entire response
          if (response.data && response.data.data) {
            console.log("Response Data:", response.data.data);  // Log response data
            toast.success(response.data.data.message || "Collection reset successfully!");
          } else {
            toast.success("Collection reset successfully!");
          }
        } catch (error) {
          console.error("Error resetting collection:", error);
          toast.error("Failed to reset collection", {});
        }
      }
    });
  };
  
  return (
    <div className={Styles.regmaincontainer}>
      <div className={Styles.regform}>
        <div className={Styles.reglogo}>
          <img src={Reglogo} alt="reglogo" />
        </div >
        <h4> Export Data</h4>
        <div className={Styles.regformmain}>
          <div>
          <button  onClick={handleExportClick}>Export</button>
          </div>
          <div>
          <button  onClick={handleResetClick}>RESET</button>
          </div>
        </div>
      </div>
      <ToastContainer />

    </div>
  );
};

export default ExportData;
