import React, { useState } from "react";
import Styles from "../Styles/register.module.scss";
import Reglogo from "../Assets/logo/logo@2x.webp";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

const Admin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [buttonState, setButtonState] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showToast = (message, type = "error", callback) => {
    Swal.fire({
      title: type === "error" ? "Oops..." : "",
      text: message,
      icon: type,
      showCloseButton: true,
      showConfirmButton: false,
      customClass: {
        popup: "custom-swal",
        htmlContainer: "custom-html",
        title: "swal2-title",
      },
      backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
      timer: 4000,
      onClose: callback,
    });
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.trim() === "") {
      showToast("User Name Cannot Be Empty", "error");
    } else if (password.length < 5) {
      showToast("Password Should Be at Least 5 Characters Long", "error");
    } else {
      setButtonState(true);
      Swal.fire({
        title: "",
        showCloseButton: false,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: `
          <div style="text-align: center;">
            <div class="loader"></div>
            <div style="color: white;">Login...</div>
          </div>
        `,
        timer: 4000,
      });

      const API = process.env.REACT_APP_API_URL;

      const login = async () => {
        try {
          const response = await axios.post(`${API}/api/admin/login`, {
            email,
            password,
          });

          if (!response.data.err) {
            dispatch({
              type: "admin",
              payload: {
                login: true,
                details: response.data.details,
              },
            });
            await Swal.fire({
              title: "Login Successful!",
              icon: "success",
              showCloseButton: true,
              showConfirmButton: false,
              customClass: {
                popup: "custom-swal",
                htmlContainer: "custom-html",
                title: "swal2-title",
              },
              backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
              timer: 1500,
            });
            navigate("/");
          } else {
            showToast("Login Failed", "error");
          }
        } catch (err) {
          let errorMessage = "Something Went Wrong!";
          if (err.response) {
            if (err.response.data && err.response.data.message) {
              errorMessage = err.response.data.message;
            } else {
              errorMessage = `Error: ${err.response.status}`;
            }
          } else if (err.request) {
            errorMessage = "No response received from the server.";
          } else {
            errorMessage = err.message;
          }
          showToast(errorMessage, "error");
        } finally {
          setButtonState(false);
        }
      };

      login();
    }
  };
  return (
    <div className={Styles.regmaincontainer}>
      <div className={Styles.regform}>
        <div className={Styles.reglogo}>
          <img src={Reglogo} alt="reglogo" />
        </div>
        <h4>Administration</h4>
        <form onSubmit={handleSubmit} className={Styles.regformmain}>
        <div>
            <input
              type="email"
              placeholder="User name"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div>
            <button type="submit" disabled={buttonState}>
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Admin;
