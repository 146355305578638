// import React from "react";
// import Styles from "../Styles/score.module.scss";
// import Scorelogo from "../Assets/logo/logo@2x.webp";

// const Gamescore = () => {
//   return (
//     <div className={Styles.scoremaincontainer}>
//       <div className={Styles.scoreform}>
//         <div className={Styles.scorelogo}>
//           <img src={Scorelogo} alt="scorelogo" />
//         </div>
//         <form className={Styles.scoreformmain}>
//           <div>
//             <input type="number" placeholder="Enter unique code" />
//           </div>
//           <div>
//             <input
//               type="text"
//               placeholder="Name"
//               disabled
//               value=""
//             />
//           </div>

//           <div>
//             <h5>ENTER GAMES SCORE</h5>
//           </div>

//           <div className={Styles.rowinput}>
//             <input type="number" placeholder="C2 Bike Sprint" />
//             <input type="number" placeholder="Football Keep Ups" />
//             <input type="number" placeholder="Basketball Challenge" />
//           </div>

//           <div>
//             <button type="submit">Submit</button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Gamescore;

import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Styles from "../Styles/score.module.scss";
import Scorelogo from "../Assets/logo/logo@2x.webp";

const Gamescore = () => {
  const [inputValue, setInputValue] = useState("");
  const [userName, setUserName] = useState("");
  const [bikeScore, setBikeScore] = useState("");
  const [footballScore, setFootballScore] = useState("");
  const [basketballScore, setBasketballScore] = useState("");
  const [toastId, setToastId] = useState(null);

  const showToast = (message, type = "error", callback) => {
    const options = {
      className: "",
      onClose: callback,
    };
    if (type === "success") {
      options.className = "toastSuccess";
    }
    if (!toast.isActive(toastId)) {
      const id = toast[type](message, options);
      setToastId(id);
    }
  };

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setInputValue(value);

    if (value.length === 6) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/game_results/get_specificUser/${value}`
        );
        if (response.data && response.data.data) {
          const userData = response.data.data;
          setUserName(userData.name || "");
        } else {
          showToast(response.data.message || "User not found", "error");
          setUserName("");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        showToast("Error fetching user data", "error");
        setUserName("");
      }
    } else {
      setUserName("");
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/game_results/store_Result`,
        {
          uniqueCode: inputValue,
          programs: [
            { programNum: 1, score: bikeScore },
            { programNum: 2, score: footballScore },
            { programNum: 3, score: basketballScore },
          ],
        }
      );
      if (response.status === 200) {
        showToast("Scores submitted successfully", "success", () => {
          setInputValue("");
          setUserName("");
          setBikeScore("");
          setFootballScore("");
          setBasketballScore("");
        });
      } else {
        showToast(response.data.message || "Failed to store scores", "error");
      }
    } catch (error) {
      console.error("Error storing scores:", error);
      showToast("Error storing scores", "error");
    }
  };

  return (
    <div className={Styles.scoremaincontainer}>
      <ToastContainer />
      <div className={Styles.scoreform}>
        <div className={Styles.scorelogo}>
          <img src={Scorelogo} alt="scorelogo" />
        </div>
        <form onSubmit={handleFormSubmit} className={Styles.scoreformmain}>
          <div>
            <input
              type="number"
              placeholder="Enter unique code"
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Name"
              disabled
              value={userName}
            />
          </div>

          <div>
            <h5>ENTER GAMES SCORE</h5>
          </div>

          <div className={Styles.rowinput}>
            <input
              type="number"
              placeholder="C2 Bike Sprint"
              value={bikeScore}
              onChange={(e) => setBikeScore(e.target.value)}
            />
            <input
              type="number"
              placeholder="Football Keep Ups"
              value={footballScore}
              onChange={(e) => setFootballScore(e.target.value)}
            />
            <input
              type="number"
              placeholder="Basketball Challenge"
              value={basketballScore}
              onChange={(e) => setBasketballScore(e.target.value)}
            />
          </div>

          <div>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Gamescore;
