import React, { useState, useEffect } from "react";
import Axios from "axios";
import Styles from "../Styles/leaderboard.module.scss";
import Leaderlogo from "../Assets/logo/logo@2x.webp";
import WorldFlag from "react-world-flags";

const Leaderboard = () => {
  // Get data from server
  const [data, setData] = useState([]);
  const Api = `${process.env.REACT_APP_API_URL}/api/game_results/get_finalResult`;

  const getdata = async () => {
    try {
      let response = await Axios.get(Api);
      setData(response.data.usersWithSumOfScores);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getdata();
    const pollInterval = setInterval(() => {
      getdata();
    }, 5000);

    return () => {
      clearInterval(pollInterval);
    };
  }, []);

  const sortedData = [...data].sort((a, b) => Number(b.sumOfScores) - Number(a.sumOfScores));
  const latestData = sortedData.slice(0, 10);
  return (
    <div className={Styles.Allleadermaincontainer}>
      <div className={Styles.Allleaderlogocontainer}>
        <img src={Leaderlogo} alt="leaderboardlogo" />
        <h1>LEADERBOARD</h1>
      </div>
      <div className={Styles.Allmainscoreboardcontainer}>
        <div className={Styles.Allmainscoreboardrowheading}></div>
        {latestData.map((item, index) => (

        <div key="" className={Styles.Allmainscoreboardrow}>
          <div className={Styles.Allcontainerone}>{index + 1}</div>
          <div className={Styles.Allcontainertwo}>      {item.user.name} </div>
         
          <div className={Styles.Allseparator}>
            <WorldFlag code={item.user.country} className={Styles.flag} />
          </div>
          <div className={Styles.Allcontainerthree}>{item.sumOfScores}</div>
       
        </div>
                ))}

      </div>
    </div>
  );
};

export default Leaderboard;
